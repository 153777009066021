html, body {
    margin: 0;
  }
  
  ::-webkit-scrollbar {
    width: 0px;
  }
  
  .containerSass {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 20px 0;
    margin-top: 10px;
    margin-left: 20px;
  }
  
  .cardSass {
    flex-shrink: 0;
    width: 400px;
    height: 300px;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 20px;
  }