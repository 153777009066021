
filter{
margin-left: 0%;
margin-right: 0%;
}



main{
  min-height: 100vh;
  
}

.rating span{
  margin: 0.1rem;
}

/* .footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5vh;
  background-color: #282c34;
  color: red;
  text-align: center;
  padding: 0.5rem;
} */


.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  
  padding-bottom: 4rem;
  margin-bottom: 2rem;
}

.carousel-caption h3 {
  color: #fff;
  font-size: 1rem;
}

.carousel{
  margin-top: 1rem;
  height: auto;
  display: flex;
  overflow-y: auto;
}
.thumbnail {
  border: 0;
  border-color: gray;
  }

.carousel img {
  
  display:flex;
  height: auto;
  padding: 15px;
  padding-top: 10px;
  margin: 40px;
  border-radius: 5%;
  
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}


