
.skeleton {
    background: #ddd;
    margin: 10px 0; /*top and bottom margin*/
    border-radius: 4px;
}

.skeleton.text {
    width: 100%;
    height: 12px;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
    
}

.skeleton.photo {
    width: 300px;
    height: 200px;
    border-radius: 5px;
    padding-left: 20px;
}

.skeleton.feature {
    max-width: 400px;
    height: 300px;
    border-radius: 5px;
}

.skeleton-wrapper {
    position: relative;
    margin: 20px auto;
    padding: 10px 15px;
    align-items: center;

}


/* Animation */

.shimmer-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation: loading 0.60s infinite;
    overflow: hidden;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(225, 225, 225, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
    0% { transform: translateX(-150%)}
    50% { transform: translateX(60%)}
    100% { transform: translateX(150%)}
}